import React from 'react'
import Slider from 'react-slick';

const TopBrand = () => {
  const images = [
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b2.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b1.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b6.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b5.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b4.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/b3.jpg',
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div>
      <p className="text-black  font-bold md:text-4xl text-3xl text-center">
        <span className="text-[#93c83f]">TOP </span> BRANDS
      </p>

      <div className=" " >
        <Slider {...settings} className='w-[80vw] '>
          {images.map((image, index) => (
            <div key={index} className=" p-2">
              <img src={image} className="h-44 object-contain"  alt=''/>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default TopBrand
