
import React from 'react'

const Services = () => {
  return (
    <div>
      <div className="flex justify-center items-center gap-2">
        <p className="text-black text-4xl flex font-semibold ">Try our </p>
        <span className="text-blue-800 text-2xl md:text-3xl lg:text-4xl">Services</span>{' '}
      </div>
      <p className="flex justify-center items-center text-[#7A7A7A] p-4">
        Call us on +91 9971737435 for a demo
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2">
        <div className="shadow-2xl flex flex-col justify-center items-center  border-gray-300 border-2 hover:border hover:border-gray-900 py-4 px-2">
          <img
            src="https://damatiinfotech.in/wp-content/uploads/2021/01/Monitoring-Icon-Converted-300x172.png"
            alt=""
            className="h-12 md:h-20"
          />
          <p className="text-center text-blue-800">Suspicious Activity</p>
          <p className="text-center text-[#7A7A7A] ">Home security</p>
        </div>

        <div className="shadow-2xl flex flex-col justify-center items-center  border-gray-300 border-2 hover:border hover:border-gray-900 py-4 px-2">
          <img
            src="https://damatiinfotech.in/wp-content/uploads/2021/01/SS-24-7-user-support.png"
            alt=""
            className="h-12 md:h-20"
          />
          <p className="text-center text-blue-800">24/7 Monitoring</p>
          <p className="text-center text-[#7A7A7A] text-base ">Every single activity</p>
        </div>

        <div className="shadow-2xl flex flex-col justify-center items-center  border-gray-300 hover:border hover:border-gray-900 border-2 py-4 px-2">
          <img
            src="https://damatiinfotech.in/wp-content/uploads/2021/01/cctv-icon.png"
            alt=""
            className="h-12 md:h-20"
          />
          <p className="text-center text-blue-800">Secures you</p>
          <p className="text-center text-[#7A7A7A] ">When you're not at home</p>
        </div>
      </div>

    </div>
  )
}

export default Services
