import ReachToUs from 'Pages/AboutUs/reachtous'
import ClearIcon from '@mui/icons-material/Clear'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import { postFormDataFn } from 'Services/ContactUs'
import ContactSchema from 'Schemas'
import Loader from 'Components/Common/Loader'

const Services = () => {
  const details = [
    {
      id: 1,
      image: 'https://kintronics.com/wp-content/uploads/2019/08/IP-camera-systems-Gear5.jpg',
      Title: 'Surveillance system ',
      details:
        'Surveillance is the monitoring of behavior, many activities, or information for the purpose of information gathering, influencing, managing, or directing.',
    },
    {
      id: 2,
      image: 'https://5.imimg.com/data5/JG/UA/NN/SELLER-17900959/iot-devices-500x500.jpg',
      Title: 'Home Automation ',
      details:
        'Home automation system will monitor and/or control home attributes such as lighting, climate, entertainment systems, and appliances. It may also include home security such as access control and alarm systems',
    },
    {
      id: 3,
      image:
        'https://5.imimg.com/data5/SELLER/Default/2023/5/311033942/ZA/YL/FW/42132029/access-control-system-installation-services.jpg',
      Title: 'Access Control services',
      details:
        'Access Control Service, or Windows Azure Access Control Service was a Microsoft-owned cloud-based service that provided an easy way of authenticating and authorizing users to gain access to web applications ...',
    },
    {
      id: 4,
      image:
        'https://www.transportadvancement.com/wp-content/uploads/2019/12/AI_traffic_management-1000x570.jpg',
      Title: 'Traffic Management services',
      details:
        'Smart traffic management systems enable safe transportation. Intelligent traffic systems help to limit and mitigate traffic collisions, fatalities, injuries, and property damage through its inherent advanced safety properties.',
    },
    {
      id: 5,
      image:
        'https://images.squarespace-cdn.com/content/v1/606df7dd6b47261d19840040/4a306411-b07b-4142-abf6-df1950a6ba63/underground%2C+gated+parking+facility',
      Title: 'Parking management services ',
      details:
        'Smart parking is an IoT (Internet of Things) solution that uses sensors and/or cameras in combination with a software to inform users of vacant parking spaces in a certain area....',
    },
    {
      id: 6,
      image:
        'https://5.imimg.com/data5/SELLER/Default/2024/2/394300624/QY/GG/AK/44958574/home-office-automation-service-500x500.jpg',
      Title: 'Office automation services  ',
      details:
        'Smart office automation is a tool that enables us to manage our daily routine in the workspace without any human intervention. These technology-based systems helps to collect, organize, and analyze everyday tasks.',
    },
    {
      id: 7,
      image:
        'https://a2z-bucket.s3.ap-south-1.amazonaws.com/wp-content/uploads/2019/11/795202-telecomsector-013118.jpg',
      Title: 'Telecommunication service',
      details:
        'A service provided by a telecommunications provider, or a specified set of user-information transfer capabilities provided to a group of users by a telecommunications system.',
    },
  ]

  const [toggleForm, setToggleForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      setIsLoading(false)
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.')
        handleReset()
        setToggleForm(false)
      }
    },
    onError: (error) => {
      setIsLoading(false)
      console.error('Error response:', error)
    },
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    customer_messages: '',
  }

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } =
    useFormik({
      initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values) => {
        const formData = new FormData()
        Object.keys(values).forEach((key) => formData.append(key, values[key]))
        postFormData(formData)
      },
    })

  return (
    <>
      <div className="relative w-full h-80  ">
        <img
          src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">
          <p className="text-white text-4xl font-extrabold">Services</p>
        </div>
      </div>
      <div className="p-8 w-full bg-white"></div>
      <div className="bg-white flex flex-col justify-center items-center pt-10 ">
        <div className=" flex center justify-center items-center lg:w-[60%] w-full">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
            {details.map((details) => (
              <div key={details.id} className="flex  gap-3 flex-col items-center text-center">
                <img src={details.image} alt="" className="h-52 w-80 object-cover" />
                <p className=" text-black font-medium text-xl text-center">{details.Title}</p>
                <p className=" text-[#7A7A7A] lg:p-0 p-6">{details.details}</p>
                <div
                  className="flex justify-center items-center bg-white text-blue-800 border border-blue-800 mt-3 p-2 w-36 font-semibold hover:text-white hover:bg-blue-800 cursor-pointer"
                  onClick={() => setToggleForm(true)}
                >
                  {' '}
                  Click More Info{' '}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-[60%] w-full text-center p-4">
          <ReachToUs />
        </div>
      </div>
      {toggleForm && (
        <div className="fixed bg-black bg-opacity-20 top-0 left-0 w-screen h-screen grid place-content-center">
          <div className="bg-white w-[90vw] max-w-[500px] rounded-md p-4 relative">
            <ClearIcon
              fontSize="medium"
              className="!text-blue-800 cursor-pointer absolute right-3 top-3"
              onClick={() => setToggleForm(false)}
            />
            <p className=" text-2xl font-bold text-black">Contact Us</p>
            <form className="flex flex-col text-gray-600 mt-4 text-base" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6">
                <div className="flex flex-col">
                  <label htmlFor="first_name" className="text-base font-bold">
                    First Name
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.first_name && touched.first_name ? (
                    <div className="text-red-600 text-sm">{errors.first_name}</div>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="mt-5 text-base font-bold">
                  Email
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.email && touched.email ? (
                  <div className="text-red-600 text-sm">{errors.email}</div>
                ) : null}

                <label htmlFor="mobile_no" className="mt-5 text-base font-bold">
                  Phone
                </label>
                <input
                  id="mobile_no"
                  type="text"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.mobile_no && touched.mobile_no ? (
                  <div className="text-red-600 text-sm">{errors.mobile_no}</div>
                ) : null}

                <label htmlFor="customer_messages" className="mt-5 text-base font-bold">
                  Comment or Message
                </label>
                <textarea
                  id="customer_messages"
                  name="customer_messages"
                  value={values.customer_messages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="border border-gray-300"
                  rows="4"
                  cols="50"
                />
                {errors.customer_messages && touched.customer_messages ? (
                  <div className="text-red-600 text-sm">{errors.customer_messages}</div>
                ) : null}

                <div className="flex justify-center items-center mt-4">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <button
                      type="submit"
                      className="px-8 py-2 bg-gradient-to-r from-[#4b6cb7] to-[#182848] text-white text-base"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
              {successMessage && (
                <div className="mt-4 text-green-600 font-bold">{successMessage}</div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  )
}
export default Services
