import Slider from 'react-slick'
import ass from "./images/asianpaints.jpeg"
import goal from './images/goel.jpeg'
import airforce from './images/indianairforce.jpeg'
import jaipuriya from "./images/jaipuriya.jpeg"
import uprnss from "./images/upranss.jpeg"
const Brands = () => {
  const brands = [
    {
      id: 1,
      images: 'https://www.shalimarcorp.com/images/head_logo_1.png',
    },
    {
      id: 2,
      images: 'https://www.babainfra.com/assets/images/Babainfra-logo.png',
    },
    {
      id: 3,
      images: jaipuriya,
    },
    {
      id: 4,
      images: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Indian_Oil_Logo.svg/800px-Indian_Oil_Logo.svg.png",
    },
    {
      id: 5,
      images: airforce,
    },
    {
      id: 6,
      images: 'https://www.trivenialmirah.com/frontend/assets/images/logo.png',
    },
    {
      id: 7,
      images: uprnss,
    },
    {
      id: 8,
      images:
        'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgNf7FjrriuG-K7nvyqxbC_ksknDk2wB3DO89hrnN5gBQePnk4gRBc1XtNZ-ZjIfgtOY00841FJk-8BlZfpeAtMuh7djTTUwD-A-hr3ABMzfRyVzLZBXlJchBP3AFHxORFzhQKx3fDcH4kg/s1145/pngwing.com.png',
    },
    {
      id: 9,
      images: ass,
    },
    {
      id: 10,
      images: goal,
    },
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div className='flex flex-col gap-5 justify-center item-center'>
      <p className="text-black  font-bold md:text-4xl text-3xl text-center">
        <span className="text-[#93c83f]">OUR</span> CLIENTS
      </p>

      <div className="">
        <Slider {...settings} className="w-[80vw]">
          {brands.map((image) => (
            <div key={image.id} className='gap-5 '>
              <img src={image.images} alt="" className="h-44 object-contain px-4" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Brands
