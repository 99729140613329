import { Call } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import Loader from 'Components/Common/Loader'
import { useFormik } from 'formik'
import TopBrand from 'Pages/AboutUs/topbrand'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import ContactSchema from 'Schemas'
import { postFormDataFn } from 'Services/ContactUs'
import Brands from './brands'

/**
 * Home component.
 * Represents the home page of the application.
 * @returns {JSX.Element} Home component JSX.
 */
const Home = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const blogFn = async () => {
    const response = await axios.get('https://m1blog.aaragroups.com/blog/blog-api/', {
      headers: {
        Authorization: 'Token d20fc1257c99fa3ea30e2a8e18080552bef8d7b2',
      },
    })
    setData(response.data.data)
  }

  useEffect(() => {
    blogFn()
  }, [])

  const [toggleForm, setToggleForm] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  }

  const [successMessage, setSuccessMessage] = useState('')

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      setIsLoading(false)
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.')
        handleReset()
        setToggleForm(false)
      }
    },
    onError: (error) => {
      setIsLoading(false)
      console.error('Error response:', error)
    },
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    customer_messages: '',
  }

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } =
    useFormik({
      initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values) => {
        const formData = new FormData()
        Object.keys(values).forEach((key) => formData.append(key, values[key]))
        postFormData(formData)
      },
    })

  return (
    <div className=" bg-white relative overflow-hidden">
      <Slider {...settings} className=" ">
        <div className="">
          <img
            src="https://www.cctvcameradelhi.in/images/bann2.jpg"
            alt=""
            className="lg:h-[500px] h-[350px] !w-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://www.cctvcameradelhi.in/images/bann1.jpg"
            alt=""
            className="lg:h-[500px] h-[350px] !w-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://www.cctvcameradelhi.in/images/bann3.jpg"
            alt=""
            className="lg:h-[500px] h-[350px] !w-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://www.cctvcameradelhi.in/images/bann4.jpg"
            alt=""
            className="lg:h-[500px] h-[350px] !w-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://www.cctvcameradelhi.in/images/bann1.jpg"
            alt=""
            className="lg:h-[500px] h-[350px] !w-full object-cover"
          />
        </div>
      </Slider>

      <div className=" bg-white p-10"></div>
      <div className=" flex justify-center flex-col items-center bg-white mb-10">
        <p className=" text-4xl font-bold text-black ">
          {' '}
          <strong className="text-[#93c83f]">OUR</strong> SERVICES
        </p>
        <div className="bg-teal-400 h-[2px] w-24"></div>
        <p className=" text-black font-medium pt-5 text-center px-4">
          Premium-Quality Full HD 1080p CCTV Security Cameras Video Surveillance System with Night
          Vision At Affordable Price
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:w-[80%] lg:w-[80%] xl:w-[65%] mx-auto py-2 gap-4 px-4">
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://kintronics.com/wp-content/uploads/2019/08/IP-camera-systems-Gear5.jpg"
                className=" w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#07c2d3] p-2 text-center font-bold text-sm xl:text-base">
              Surveillance System
            </p>
          </div>
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://5.imimg.com/data5/JG/UA/NN/SELLER-17900959/iot-devices-500x500.jpg"
                className=" w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#e77e23] p-2 text-center font-bold text-sm xl:text-base ">
              Home Automation
            </p>
          </div>
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2023/5/311033942/ZA/YL/FW/42132029/access-control-system-installation-services.jpg"
                className=" w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#71ba51] p-2 text-center font-bold text-sm xl:text-base">
              Access Control Services
            </p>
          </div>
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://www.transportadvancement.com/wp-content/uploads/2019/12/AI_traffic_management-1000x570.jpg"
                className=" w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#d33156] p-2 text-center font-bold text-sm xl:text-base">
              Traffic Management Services
            </p>
          </div>
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://images.squarespace-cdn.com/content/v1/606df7dd6b47261d19840040/4a306411-b07b-4142-abf6-df1950a6ba63/underground%2C+gated+parking+facility"
                className="w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#c73387] p-2  text-center font-bold text-sm xl:text-base">
              Parking Management Services
            </p>
          </div>
          <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2024/2/394300624/QY/GG/AK/44958574/home-office-automation-service-500x500.jpg"
                className="w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#37adf4] p-2 text-center font-bold text-sm xl:text-base">
              Office Automation Services
            </p>
          </div>
          <div className="flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden">
            <p
              onClick={() => setToggleForm(true)}
              className="cursor-pointer w-full h-[80%] overflow-hidden"
            >
              <img
                src="https://a2z-bucket.s3.ap-south-1.amazonaws.com/wp-content/uploads/2019/11/795202-telecomsector-013118.jpg"
                className="w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#f3ac1e] p-2  text-center font-bold text-sm xl:text-base">
              Telecommunication Service
            </p>
          </div>
          {/*   <div
            onClick={() => setToggleForm(true)}
            className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden"
          >
            <p className="w-full h-[80%] overflow-hidden">
              <img
                src="https://4.imimg.com/data4/MR/RF/MY-4916988/1-500x500.jpg"
                className="w-full h-full object-cover hover:scale-105 duration-200"
                alt=""
              />
            </p>
            <p className="text-[#3956d0] p-2 text-center font-bold text-sm xl:text-base">
              Interion/EPABX
            </p>
          </div> */}
        </div>
        <Link to="/services">
          <button
            type="button"
            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 text-xl dark:focus:ring-cyan-800 font-medium rounded-lg mt-10 p-4 text-center"
          >
            View More Services
          </button>
        </Link>
      </div>
      <div
        className="flex justify-center items-center min-h-[100vh] bg-cover bg-center px-4"
        style={{
          backgroundImage: 'url("https://www.cctvcameradelhi.in/images/bann2.jpg")',
          backgroundAttachment: 'fixed',
          zIndex: -1,
        }}
      >
        <div className="bg-sky-900 lg:w-[90%] w-[90%] p-4 my-10 max-w-[1200px]">
          <div className="flex flex-col md:p-10 items-center">
            <p className="text-3xl font-bold">WHY CHOOSE US</p>
            <p className="h-1 bg-[#07c2d3] w-40"></p>
            <p className="font-medium lg:w-[70%] text-center w-[80%] font-sans pt-4">
              Our CCTV surveillance camera systems are trusted by more than 1,000 businesses,
              universities, and government facilities, and residential customers. Enjoy superior
              quality cameras, A 2-years warranty and unlimited support from the industry's #1 tech
              support team.
            </p>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10 pt-4">
              <div className="flex flex-col text-center justify-center items-center ">
                <img
                  src="https://www.cctvcameradelhi.in/images/Online-Streaming.png"
                  alt=""
                  className="hover:rotate-180 duration-500"
                />
                <p className="text-[#93c83f] text-xl font-bold">Online Streaming</p>
                <p className="text-white">
                  High quality CCTV cameras live video to your phone and PC.
                </p>
              </div>
              <div className="flex flex-col justify-center text-center items-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/delivery.png"
                  alt=""
                  className="hover:rotate-180 duration-500"
                />
                <p className="text-[#93c83f] text-xl font-bold">On Time Delivery</p>
                <p>
                  For us, customer services is always a priority than sales. Therefore, we provide
                  On-time delivery.
                </p>
              </div>
              <div className="flex flex-col justify-center text-center items-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/support.png"
                  alt=""
                  className="hover:rotate-180 duration-500"
                />
                <p className="text-[#93c83f] text-xl font-bold">Dedicated Support </p>
                <p>Dedicated Support By CCTV Camera Lucknow Security Experts. </p>
              </div>
              <div className="flex flex-col justify-center text-center items-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/Customer.png"
                  alt=""
                  className="hover:rotate-180 duration-500"
                />
                <p className="text-[#93c83f] text-xl font-bold">
                  Customer
                  <br />
                  Satisfaction
                </p>
                <p>
                  We provide great customer satisfaction to help all your query with configuration
                  and installation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center w-full gap-3 mb-10 bg-white px-4">
        <p className="w-[80%] pt-20 text-3xl font-bold text-center text-black md:text-4xl lg:w-full">
          <span className="text-[#93c83f]">INDUSTRY</span> SOLUTION WE OFFER
        </p>
        <p className="text-center text-black">
          CCTV Camera Lucknow offers solutions for a spectrum of industries, delivering
          comprehensive coverage for effective and efficient security.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-[400px_1fr] gap-4">
          <div className="flex flex-col gap-5 order-2 lg:order-1">
            <p className="text-2xl font-extrabold text-[#93c83f]">TRANSPORT & TRAFFIC</p>
            <p className="text-black">
              Effective surveillance plays a vital role in enhancing transportation quality by
              recognizing suspicious items or activities.
              <br />
              <br />
              <strong>SAFE CITY</strong>
              <br />
              City surveillance is important for ensuring the safety and security of citizens and
              deterring crime.
              <br />
              <br />
              <strong>RESIDENTIAL SOLUTION</strong>
              <br />
              Get our CCTV system installed at your home to prevent any undesired burglars from
              entering your property without consent.
            </p>
            <Link
              to={'/services'}
              className="w-40 p-2 text-center text-blue-500 border border-blue-300 rounded-3xl cursor-pointer hover:bg-blue-500 hover:text-white"
            >
              Read More
            </Link>
          </div>
          <img
            src="https://www.cctvcameradelhi.in/images/cctv-camera-for-safe-city.jpg"
            alt="Safe City CCTV Camera"
            className="order-1 lg:order-2 h-auto object-cover items-start mx-auto"
          />
        </div>
      </div>

      <div
        className="flex justify-center items-center min-h-[500px] bg-cover bg-center px-4"
        style={{
          backgroundImage: 'url("https://www.cctvcameradelhi.in/images/call-us-bg.jpg")',
          backgroundAttachment: 'fixed',
          zIndex: -1,
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className=" flex-col flex  justify-center items-center lg:w-[70%] w-[80%] ">
            <p className="text-white text-2xl font-bold pt-10 text-center">
              PREMIUM QUALITY CCTV CAMERA SOLUTION FOR ALL AROUND SECURITY
            </p>

            <p className=" text-xl text-center p-4 ">
              We are name you can trust when it comes to all-round security solutions. We understand
              that few things are inevitable, security for instance.
            </p>
            <Link
              to="tel:+918604507884"
              type="button"
              className="text-white bg-gradient-to-br from-purple-600  hover:border-2 hover:border-white to-blue-600 hover:bg-gradient-to-bl  focus:outline-none font-medium rounded-2xl text-xl px-5 py-4 text-center me-2 mb-2"
            >
              <Call /> +918604507884
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white w-full justify-center items-center max-w-[1200px] mx-auto px-4">
        <div className="flex  flex-col justify-center items-center  pt-20 ">
          <p className="text-black lg:text-4xl text-3xl font-bold text-center lg:p-0">
            <sapan className="text-[#93c83f]">CCTV CAMERA</sapan> INSTALLATION LUCKNOW
          </p>
          <p className="h-[2px] bg-[#07c2d3] w-40"></p>
          <p className="text-black pt-3 lg:p-0 p-4">
            We deal in CCTV cameras, biometric systems, Video Door Phone, EPABX System, which are
            high performance and highly sensitive.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-[400px_1fr] gap-4 mt-4">
            <img
              src="https://www.cctvcameradelhi.in/images/cctv-camera-installation-in-delhi.jpg"
              alt=""
              className="order-2 lg:order-1 h-auto object-cover items-start mx-auto"
            />

            <p className=" text-black  order-1 lg:order-1">
              CCTV Camera Lucknow is a technological solutions provider. We are a Lucknow based
              Company engaged in supply and installation of premium quality CCTV Camera &
              Surveillance Systems for Home & Business Security with complete Installation & AMC
              Service at affordable price in Lucknow. In a very short span of time, we have become
              one of the most trusted and leading
              <strong> CCTV Camera Installation Company in Lucknow</strong> largely thanks to our
              loyal customer base and our team of well-trained, vastly experienced, dedicated, &
              hard working professionals. Our customers design best possible CCTV camera &
              Surveillance Systems for their Home and Business Security according to their need and
              budget keeping the overall quality of the CCTV camera at the highest possible level.
              We provide intelligent, affordable, customized solutions with Premium Quality
              <strong> CCTV camera installation</strong> with comprehensive Warranty, excellent
              After-Sales Service, and Customer Support.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5  items-center p-10 bg-white">
        <Brands />
      </div>
      <div className="flex flex-col gap-5  items-center p-10 bg-white">
        <TopBrand />
      </div>

      <div className="flex flex-col gap-3  items-center p-10 pv-10 bg-white">
        <p className="text-black  font-bold md:text-4xl text-3xl">
          <span className="text-[#93c83f]">OUR </span>LATEST BLOG{' '}
        </p>
        <p className="h-[2px] bg-[#07c2d3] w-28"></p>
        <p className="text-black">Checkout our blog articles and product updates </p>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:w-[65%] w-full">
          {data.map((blog) => (
            <Link
              to={'/blogs'}
              key={blog.id}
              className="flex flex-col items-start justify-center w-full h-full p-6 pt-10 shadow-lg"
            >
              <p className="h-[70%]">
                {blog.images && (
                  <img src={blog.images} alt={blog.title} className="object-cover h-full w-full" />
                )}
              </p>
              <p className="p-2 text-sm font-semibold text-center text-blue-500 md:text-base lg:text-lg hover:text-green-500">
                {blog.title}
              </p>
            </Link>
          ))}
        </div>
      </div>
      {toggleForm && (
        <div className="fixed bg-black bg-opacity-20 top-0 left-0 w-screen h-screen grid place-content-center">
          <div className="bg-white w-[90vw] max-w-[500px] rounded-md p-4 relative">
            <ClearIcon
              fontSize="medium"
              className="!text-blue-800 cursor-pointer absolute right-3 top-3"
              onClick={() => setToggleForm(false)}
            />
            <p className=" text-2xl font-bold text-black">Contact Us</p>
            <form className="flex flex-col text-gray-600 mt-4 text-base" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6">
                <div className="flex flex-col">
                  <label htmlFor="first_name" className="text-base font-bold">
                    Name
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.first_name && touched.first_name ? (
                    <div className="text-red-600 text-sm">{errors.first_name}</div>
                  ) : null}
                </div>

                {/* <div className="flex flex-col">
                  <label htmlFor="last_name" className="text-base font-bold">
                    Last Name
                  </label>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.last_name && touched.last_name ? (
                    <div className="text-red-600 text-sm">{errors.last_name}</div>
                  ) : null}
                </div> */}
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="mt-5 text-base font-bold">
                  Email
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.email && touched.email ? (
                  <div className="text-red-600 text-sm">{errors.email}</div>
                ) : null}

                <label htmlFor="mobile_no" className="mt-5 text-base font-bold">
                  Phone
                </label>
                <input
                  id="mobile_no"
                  type="text"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.mobile_no && touched.mobile_no ? (
                  <div className="text-red-600 text-sm">{errors.mobile_no}</div>
                ) : null}

                <label htmlFor="customer_messages" className="mt-5 text-base font-bold">
                  Comment or Message
                </label>
                <textarea
                  id="customer_messages"
                  name="customer_messages"
                  value={values.customer_messages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="border border-gray-300"
                  rows="4"
                  cols="50"
                />
                {errors.customer_messages && touched.customer_messages ? (
                  <div className="text-red-600 text-sm">{errors.customer_messages}</div>
                ) : null}

                <div className="flex justify-center items-center mt-4">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <button
                      button
                      type="submit"
                      className="px-8 py-2 bg-gradient-to-r from-[#4b6cb7] to-[#182848] text-white text-base"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>

            {/* Success Message */}
            {successMessage && (
              <div className="mt-4 text-green-600 font-bold">{successMessage}</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
