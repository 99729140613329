import React from 'react'
import Slider from 'react-slick'

const OurClient = () => {
  const images = [
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L2.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L1.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L6.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L5.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L4.jpg',
    'https://damatiinfotech.in/wp-content/uploads/2021/01/L3.jpg',
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div>
      <p className="p-2 flex justify-center items-center text-blue-800 text-lg font-bold">
        Reliable and Trusted
      </p>
      <div className="flex gap-3 font-semibold justify-center items-center">
        <span className="text-[#93c83f]  md:text-3xl lg:text-4xl ">OUR </span>
        <p className="text-2xl  md:text-3xl lg:text-4xl flex text-black"> CLIENTS</p>
      </div>
      <div className="carousel-container h-60 p-10">
        <Slider {...settings} className="w-[80vw] lg:w-auto !mx-auto">
          {images.map((image, index) => (
            <div key={index} className="carousel-slide mx-auto">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-1/2 md:w-full mx-auto h-auto"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default OurClient
