import Brands from 'Pages/Home/brands'
import ReachToUs from './reachtous'
import Services from './services'
import TopBrand from './topbrand'

const AboutUs = () => {
  return (
    <div className="">
      <div className="relative w-full h-80 overflow-hidden">
        <img
          src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">

          <p className="text-white text-4xl font-extrabold">About Us</p>

        </div>
      </div>
      <div className=" flex flex-col items-center justify-center bg-white gap-10">
        <div className="flex flex-col justify-center items-center w-full lg:w-[70%] p-4 bg-white ">
          <p className="text-black p-2 text-2xl md:text-3xl lg:text-4xl font-semibold text-center">
            Lucknow Technical Store CCTV Camera Installation Company in Lucknow
          </p>
          <p className="text-center text-[#7A7A7A] w-[90%]">
            At Lucknow Technical Store, we are more than just a{' '}
            <strong className="text-black">CCTV Camera Installation Company</strong> .We're a
            dedicated team of professionals who truly believe in the power of security and peace of
            mind. With each camera we install, there's an underlying story; a family sleeping
            soundly at night knowing they are protected or business owners feeling confident about
            the safety of their assets. For us, it isn't merely about providing a service; it is all
            about creating safer spaces for our clients.
          </p>
          <p className="text-center text-[#7A7A7A] w-[90%] p-8">
            Each corner we secure holds tremendous value to us because we understand that behind
            every successful installation lies our client's trust and faith in our services. Our
            journey began with a simple belief-leveraging technology to create safe environments. As
            we continue to grow, so does this belief which fuels.{' '}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center p-4 gap-10 w-full lg:w-[70%]">
          <div className="p-4  flex-col flex !justify-center !items-center w-full sm:w-[50%] ">
            <img
              src="https://damatiinfotech.in/wp-content/uploads/2021/01/cctv-icon.png"
              alt=""
              className="p-4 h-48 w-48"
            />
            <p className="text-3xl font-semibold text-black"> <strong className='text-blue-800'>Who We</strong> Are</p>
            <p className="text-center text-[#7A7A7A] p-4">
              We are working since 2014 in security systems. We deals in security systems &
              Desktop/Mobile accessories or provide services to our valuable clients.{' '}
            </p>
          </div>
          <div className="p-4 flex-col flex !justify-center !items-center  w-full sm:w-[50%] ">
            <img
              src="https://damatiinfotech.in/wp-content/uploads/2021/01/SS-24-7-user-support.png"
              alt=""
              className="p-4   h-48 w-48"
            />
            <p className="text-3xl font-semibold text-black">Our <strong className='text-blue-800'>Mission</strong></p>
            <p className="text-center text-[#7A7A7A]">
              We believe to serve safety & quality in security sector and that what we work for. Our
              mission is to provide the best security products to make your home, offices safe &
              secure 24/7.
            </p>
          </div>
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <Brands />
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <TopBrand />
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <Services />
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <ReachToUs />
        </div>
      </div>

    </div>
  )
}

export default AboutUs
