import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate= useNavigate();

  const blogFn = async () => {
   
      const response = await axios.get('https://m1blog.aaragroups.com/blog/blog-api/',
        {
          headers: {
            Authorization: 'Token d20fc1257c99fa3ea30e2a8e18080552bef8d7b2',
          },
        });
      setData(response.data.data);
     console.log(response.data)
      setLoading(false);
    
  };

  useEffect(() => {
    blogFn();
  }, []);

  const handleImageClick = (id) => {
    navigate(`/blogs/${id}`); 
  };

  const removeHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+>/gi, ''); // Remove HTML tags
  };

  const truncateContent = (content, wordLimit) => {
    const plainText = removeHtmlTags(content); // Remove HTML tags
    const words = plainText.split(/\s+/); // Split plain text into words
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Return first 'wordLimit' words with ellipsis
    }
    return plainText; // Return original content if within limit
  };

  return (
    <div className="w-full h-full bg-white flex flex-col justify-center items-center">
      <div className="relative w-full h-80 overflow-hidden">
        <img
          src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">

          <p className="text-white text-4xl font-extrabold">BLOGS</p>

        </div>
        </div>
        <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 grid-cols-1">
          {data.map((blog) => (
            <div key={blog.id} className="pt-10 flex flex-col justify-center items-center p-6 cursor-pointer">
              {blog.images && (
                <img
                  src={blog.images}
                  alt={blog.title}
                  className="h-[50%] w-[100%] object-cover cursor-pointer"
                  onClick={() => handleImageClick(blog.id)} 
                />
              )}
              <p className="text-black text-2xl font-bold p-2">{blog.title}</p>
              <p className="text-gray-800 w-full p-2">
                {truncateContent(blog.Content, 54)} 
              </p>
            </div>
          ))}
        </div>
      
    </div>
  );
};
export default Blogs;
