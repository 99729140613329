import ReachToUs from 'Pages/AboutUs/reachtous'
import ClearIcon from '@mui/icons-material/Clear'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import { postFormDataFn } from 'Services/ContactUs'
import ContactSchema from 'Schemas'
import Loader from 'Components/Common/Loader'

const Product = () => {
  const details = [
    {
      id: 1,
      image:
        'https://5.imimg.com/data5/SELLER/Default/2023/4/303063086/QJ/EX/JO/7121818/smart-home-automation.png',
      Title: 'TATA Home Automation',
      details:
        'Tata Power EZ Home solutions are designed to offer you countless benefits and comfort at competitive costs. Use our simple to use home automation estimation ...',
    },
    {
      id: 2,
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS68IiB-nFQuqklMk6fVvjlCEf0wVMCC39WQQ&s',
      Title: 'CCTV HD/IP Wireless',
      details:
        "Wireless IP cameras that can be mounted anywhere without the need to run video cables. CCTV Camera World's HD wireless IP cameras come with easy remote...",
    },
    {
      id: 3,
      image:
        'https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2022/07/speaker-audio-sound.jpg',
      Title: 'Speaker/ Audio System',
      details:
        'Shop for best Speakers online at affordable prices on Ooberpad. Select Home Theater Speakers offered by brands like Elac, Monitor Audio, Polk Audio, Bowers and ...',
    },
    {
      id: 4,
      image:
        'https://s3.ap-south-1.amazonaws.com/brandbuddiez.com/64/img/blog/3/1000%20%20x%20319%20%28Video%20Door%20Phone%29%20Godrej.webp',
      Title: 'Video Door Phone',
      details:
        'Video Door Phones. Introducing a tech-smart, easy-to-use way of front door surveillance, CP PLUS has designed a high-performing, all-in-one Video Door phone ...',
    },
    {
      id: 5,
      image:
        'https://www.mivanta.com/Assets/All-Common/images/menu/Products/Biometric-Attendance-System/Time-Attendance-and-Access-Control.jpg',
      Title: 'Biometric attendance Solution',
      details:
        "Biometric Attendance Machine - The CP PLUS biometric machine recognises an individual's physiological traits such as fingerprint & facial recognition to ...",
    },
    {
      id: 6,
      image:
        'https://damatiinfotech.in/wp-content/uploads/2023/09/CCTV-Camera-Installation-Ghaziabad.jpg',
      Title: 'Home Automation',
      details:
        'Improve safety & security with CCTV Surveillance Cameras. Learn about their importance, benefits, and how they can protect your assets effectively…',
    },
    {
      id: 7,
      image:
        'https://img.etimg.com/thumb/width-420,height-315,imgsize-35920,resizemode-75,msid-101504267/news/india/indias-public-wifi-coverage-falls-way-short-of-target/wi-fi-internet-router.jpg',
      Title: 'Wifi Zone ',
      details:
        'We have expertise of Wi-Fi service. We can create, install and manage WIFI service at residential and commercial projects. We are providing Wi-Fi zone to the ...',
    },
    {
      id: 8,
      image: 'https://4.imimg.com/data4/MR/RF/MY-4916988/1-500x500.jpg',
      Title: 'Interion/EPABX',
      details:
        'GSM EPABXSolution is an unique offering that eliminates delays caused due to wireline feasibility checks and permissions. It is a …',
    },
    {
      id: 9,
      image:
        'https://reliablesolarpanelinstallationfirm.weebly.com/uploads/1/4/5/2/145270014/solar-panel-installation_orig.jpg',
      Title: 'Solar Plants',
      details:
        ' a facility designed for converting solar energy into electricity through a conventional thermodynamic cycle…',
    },
    {
      id: 10,
      image: 'https://3.imimg.com/data3/PP/QU/MY-2983177/fire-alarm-intrusion-detection-system.jpg',
      Title: 'Instrusion/Theft/Frire Alarms',
      details:
        'he first line of defense against medical problems, fire, theft and home invasion. Our systems work 24 hours per day to alert you on your mobile devices and through our 24-hour monitoring center.',
    },
    {
      id: 11,
      image: 'https://www.kanexfire.com/images/product/img-1.png',
      Title: 'Fire Fighting Equipments',
      details:
        'Fire offers a wide range of fire protection equipment, such as Fire Extinguishers, Fire Suppression Systems, Water Mist Fire Extinguishers, and related ...',
    },

    {
      id: 12,
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR74m9prsB8Qd88J4bgEXRRodyu7zuVHO4XpQ&s',
      Title: 'Boom Barrier/ Parking System',
      details:
        'Integrated one-stop access and parking solution for society and corporates. An original, cutting-edge, secure solution for all parking and security-related...',
    },
  ]
  const [toggleForm, setToggleForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      setIsLoading(false)
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.')
        handleReset()
        setToggleForm(false)
      }
    },
    onError: (error) => {
      setIsLoading(false)
      console.error('Error response:', error)
    },
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    customer_messages: '',
  }

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } =
    useFormik({
      initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values) => {
        const formData = new FormData()
        Object.keys(values).forEach((key) => formData.append(key, values[key]))
        postFormData(formData)
      },
    })

  return (
    <>
      <div className="flex gap-3 font-semibold justify-center items-center">
        <span className="text-[#93c83f]  md:text-3xl lg:text-4xl ">OUR </span>
        <p className="text-2xl  md:text-3xl lg:text-4xl flex text-black"> Products</p>
      </div>
      <div className="p-6 w-full bg-white"></div>
      <div className="bg-white flex flex-col justify-center items-center pt-5 ">
        <div className=" flex center justify-center items-center lg:w-[60%] w-full">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
            {details.map((details) => (
              <div key={details.id} className="flex  gap-3 flex-col items-center text-center">
                <img src={details.image} alt="" className="h-52 w-80 object-cover" />
                <p className=" text-black font-medium text-xl text-center">{details.Title}</p>
                <p className=" text-[#7A7A7A] lg:p-0 p-6">{details.details}</p>
                <div
                  className="flex justify-center items-center bg-white text-blue-800 border border-blue-800 mt-3 p-2 w-36 font-semibold hover:text-white hover:bg-blue-800 cursor-pointer"
                  onClick={() => setToggleForm(true)}
                >
                  {' '}
                  Click More Info{' '}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-[60%] w-full text-center p-4">
          <ReachToUs />
        </div>
      </div>
      {toggleForm && (
        <div className="fixed bg-black bg-opacity-20 top-0 left-0 w-screen h-screen grid place-content-center">
          <div className="bg-white w-[90vw] max-w-[500px] rounded-md p-4 relative">
            <ClearIcon
              fontSize="medium"
              className="!text-blue-800 cursor-pointer absolute right-3 top-3"
              onClick={() => setToggleForm(false)}
            />
            <p className=" text-2xl font-bold text-black">Contact Us</p>
            <form className="flex flex-col text-gray-600 mt-4 text-base" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6">
                <div className="flex flex-col">
                  <label htmlFor="first_name" className="text-base font-bold">
                    First Name
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.first_name && touched.first_name ? (
                    <div className="text-red-600 text-sm">{errors.first_name}</div>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="mt-5 text-base font-bold">
                  Email
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.email && touched.email ? (
                  <div className="text-red-600 text-sm">{errors.email}</div>
                ) : null}

                <label htmlFor="mobile_no" className="mt-5 text-base font-bold">
                  Phone
                </label>
                <input
                  id="mobile_no"
                  type="text"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.mobile_no && touched.mobile_no ? (
                  <div className="text-red-600 text-sm">{errors.mobile_no}</div>
                ) : null}

                <label htmlFor="customer_messages" className="mt-5 text-base font-bold">
                  Comment or Message
                </label>
                <textarea
                  id="customer_messages"
                  name="customer_messages"
                  value={values.customer_messages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="border border-gray-300"
                  rows="4"
                  cols="50"
                />
                {errors.customer_messages && touched.customer_messages ? (
                  <div className="text-red-600 text-sm">{errors.customer_messages}</div>
                ) : null}

                <div className="flex justify-center items-center mt-4">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <button
                      type="submit"
                      className="px-8 py-2 bg-gradient-to-r from-[#4b6cb7] to-[#182848] text-white text-base"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
              {successMessage && (
                <div className="mt-4 text-green-600 font-bold">{successMessage}</div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default Product
