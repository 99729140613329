import ReachToUs from 'Pages/AboutUs/reachtous'

const Gallery = () => {
    const images = [
        {
            id: 1,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J4-768x576.jpg"
        },
        {
            id: 2,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J6-768x576.jpg"
        },
        {
            id: 3,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J7-768x576.jpg"
        },
        {
            id: 4,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J2-768x576.jpg"
        },
        {
            id: 5,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J5-768x576.jpg"
        },
        {
            id: 6,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J1-768x576.jpg"
        },
        {
            id: 7,
            image: "https://damatiinfotech.in/wp-content/uploads/2021/01/J3-768x576.jpg"
        },
    ]
    return (
        <div>
            <div className="relative w-full h-80 overflow-hidden">
                <img
                    src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
                    alt="Products Background"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-25"></div>
                <div className="absolute inset-0 flex px-[20%] items-center">

                    <p className="text-white text-4xl font-extrabold">GALLERY</p>

                </div>
            </div>
            <div className='flex justify-center items-center w-full bg-white'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full lg:w-[70%] gap-3 p-10'>
                    {images.map((img) => (
                        <img src={img.image} alt='' key={img.id} className='border-4 border-gray-400' />
                    ))}

                </div>
            </div>
            <div className="flex flex-col justify-center items-center bg-white">
                <div className='p-4 w-full lg:w-[70%] pb-20'>
                    <ReachToUs />
                </div>
            </div>

        </div>
    )
}

export default Gallery
