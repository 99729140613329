import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const BlogDetails = () => {
  const {id}= useParams(); // Get the blog ID from the URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
 console.log(id);
  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(`https://m1blog.aaragroups.com/blog/blog-api/?blog_id=${id}`, {
        headers: {
          Authorization: 'Token d20fc1257c99fa3ea30e2a8e18080552bef8d7b2',
        },
      });
      setData(response.data.data);
      setLoading(false);
      console.log(response.data)
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogDetails();
  }, [id]);

  if (loading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>;
  }

  

  return (
    <div className='bg-white'>
         <div className="relative w-full h-96 overflow-hidden ">
        <img
          src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">

          <p className="text-white text-4xl font-extrabold">{data.title}</p>

        </div>
        </div>
    <div className='bg-white flex justify-center items-center !pt-10'>

    <div className=" flex flex-col bg-white p-6 gap-10 lg:w-[60%] w-[100%]  text-black ">
      
      <img src={data.images} alt={data.title} className=" " />
      <div className='flex flex-col'>
      <h1 className="text-3xl font-bold">{data.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: data.Content }}
        className="text-gray-800 mt-4 flex flex-col gap-5 font-2xl lg:p-0 p-2"
      ></div>
      </div>
      </div>
    </div>
    </div>
  );

};

export default BlogDetails;
