import { Box, Tab, Tabs } from '@mui/material'
import OurClient from 'Pages/AboutUs/ourclient'
import ReachToUs from 'Pages/AboutUs/reachtous'
import TopBrand from 'Pages/AboutUs/topbrand'
import { useState } from 'react'

import Product from 'Pages/Service'

const Products = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <div className="relative w-full h-80 overflow-hidden">
        <img
          src="https://damatiinfotech.in/wp-content/uploads/2021/01/21.webp"
          alt="Products Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">
          <p className="text-white text-4xl font-extrabold">PRODUCTS</p>
        </div>
      </div>

      <div className="flex flex-col p-8 bg-white"></div>

      <div className="flex flex-col justify-center items-center bg-white p-4">
        <div className="pt-10 w-full ">
          <Product />
        </div>

        <div className="pt-10 w-full lg:w-[70%]">
          <OurClient />
        </div>

        <div className="w-full lg:w-[70%]">
          <TopBrand />
        </div>

        <div className="w-full lg:w-[70%] pb-20">
          <ReachToUs />
        </div>
      </div>
    </div>
  )
}

export default Products
