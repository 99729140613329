import { Call, Facebook, Instagram, LinkedIn, Menu, Twitter, WhatsApp } from '@mui/icons-material';
import { Drawer, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../Header/logoo.png";

const Header = ({ setMode, mode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
 

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'HOME', path: '/' },
    { text: 'ABOUT US', path: '/about-us' },
    { text: 'SERVICES', path: '/services' },
    { text: 'PRODUCT', path: '/products' },
    { text: 'GALLERY', path: '/gallery' },
    { text: 'BLOG', path: '/blogs' },
    { text: 'CONTACT US', path: '/contact-us' },
  ];

  return (
    <Paper className="flex flex-col !rounded-none">
      <div className="sm:flex hidden justify-between text-sm gap-3 p-4 text-center bg-gradient-to-r from-[#4b6cb7] to-[#182848]">
        <div className='flex px-10 gap-3 items-start !text-sm'>
          <Facebook />
          <Instagram />
          <Twitter />
          <LinkedIn />
        </div>
        <div className='flex gap-3 justify-center items-center'>
          <a href="https://wa.me/918604507884" target="_blank" rel="noopener noreferrer" className='flex items-center gap-1'>
            <WhatsApp className='mt-1' />
            <p>+91 8604507884</p>
          </a>
          <Link to="tel:+918604507884" className='flex gap-1 items-center'> <Call className='mt-1' /> <p>+91 8604507884</p></Link>
        </div>
      </div>
      <div className="flex justify-between p-1 bg-white">
        <div className='flex justify-center items-center h-16 px-5 '>
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt='' className='w-[120px] mx-2 ' />
          </Link>
        </div>
        <div className="hidden lg:flex menusection items-center gap-3 text-black font-bold">
          {menuItems.map(item => (
            <Link key={item.text} to={item.path} className="block float-left p-3 hover:text-black hover:bg-white">
              {item.text}
            </Link>
          ))}
        </div>
        <div className="lg:hidden flex items-center justify-end px-4">
          <IconButton onClick={toggleDrawer(true)}>
            <Menu className='text-black' />
          </IconButton>
        </div>
      </div>
      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'white',
            color: 'blue',
          },
        }}
      >
        <List>
          {menuItems.map(item => (
            <ListItem
              button
              component={Link}
              to={item.path}
              key={item.text}
              onClick={toggleDrawer(false)}
              sx={{ color: 'blue' }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Paper>
  );
};

export default Header;
