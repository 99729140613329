import { LocationOn, MailOutline, PhoneIphone } from '@mui/icons-material'
import { Divider } from '@mui/material'
import React from 'react'

/**
 * Footer component.
 * Represents the footer section of the application.
 * Typically contains copyright information.
 * @returns {JSX.Element} Footer component JSX
 */
import logo from '../Header/logoo.png'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>
      <div className="flex justify-center items-center w-full bg-gray-50 text-white p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-4 w-full max-w-[1200px] ">
          <div className="flex flex-col  ">
            <Link to="/">
              <img src={logo} alt="" className="w-44" />
            </Link>
            <div className="flex flex-col justify-center items-center pt-10 text-blue-800 !md:text-base">
              <p>
                <LocationOn />
                <strong> Lucknow Office : </strong>Shop No 16, Basement, Aryavart Complex, Faizabad
                Rd, opp. new High court, Lucknow, Uttar Pradesh 226010
              </p>
            </div>
          </div>

          <div className="flex flex-col text-blue-800 ">
            <p className="font-semibold flex items-center text-xl md:text-xl">QUICK LINKS</p>
            <div className="flex flex-col pt-4  gap-2 !md:text-base">
              <Link to="/blogs" className="cursor-pointer">
                <p>Blog</p>
              </Link>
              <Divider />
              <Link to="/contact-us" className="cursor-pointer">
                <p>Contact</p>
              </Link>
              <Divider />
              <Link to="/services" className="cursor-pointer">
                <p>Service</p>
              </Link>
              <Divider />
              <Link to="/about-us" className="cursor-pointer">
                <p>About</p>
              </Link>
              <Divider />
              <Link to="/about-us" className="cursor-pointer">
                <p>CCTV Camera Installation Lucknow</p>
              </Link>
            </div>
          </div>
          <div className="flex flex-col text-blue-800 !md:text-base">
            <p className="text-xl md:text-xl font-semibold flex items-center">FOR ANY SUPPORT</p>
            <div className=" flex pt-4">
              <p>Contact Person - Mr.Arun</p>{' '}
            </div>
            <div className="flex flex-col pt-2 gap-2 "></div>
            <p className="flex gap-2 cursor-pointer">
              <MailOutline />
              lucknowtechnicalstore@gmail.com
            </p>
            <Link to="tel:+918604507884" className="flex gap-2 cursor-pointer ">
              <PhoneIphone fontSize="small" />
              Phone: +91 8604507884
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center bg-gradient-to-r from-[#4b6cb7] to-[#182848] justify-center py-4 text-center text-white px-4 ">
        Copyright © 2024 lucknowtechnicalstore.com |{' '}
        <a
          href="https://www.aaragroups.com/"
          target="_blank"
          rel="noopener noreferrer"
          className=" underline ml-2"
        >
          {' '}
          Website Designed by aaragroups
        </a>
      </div>
    </div>
  )
}

export default Footer
