import { Call } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

const ReachToUs = () => {
  return (
    <div>
      <div className="flex bg-pink-50 mt-10">
        <div className="flex flex-col md:flex-row gap-5 p-8 md:p-16">
          <div className="flex-col flex w-full md:w-[55%]">
            <p className=" text-blue-800 text-2xl font-semibold">Reach to us</p>
            <p className="text-black pt-2">
              If you have any Query or Suggestion, we are happy to hear you. You can mail us on
              lucknowtechnicalstore@gmail.com or also expect a phone call from us through our
              contact page.
            </p>
          </div>
          <div className=" flex justify-center items-center h-14 mt-10 w-full md:w-[45%]">
            <Link
              to="tel:+918604507884"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black md:text-xl lg:text-2xl font-bold flex bg-white justify-center items-center p-2 hover:bg-gradient-to-r from-[#4b6cb7] to-[#182848] hover:text-white"
            >
              <Call />
              +91 8604507884
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReachToUs
